import { browserTracingIntegration, init, getCurrentScope } from '@sentry/vue'
import { Plugin, watchEffect } from 'vue'
import { name } from '../package.json'
import { currentUser } from './auth'
import { router } from './router'
import settings from './settings'

export const sentryPlugin: Plugin = (app) => {
  if (settings.MODE === 'production') {
    init({
      app,
      dsn: settings.SENTRY_URL, // process.env.VUE_APP_SENTRY_URL,
      release: `${name}@${settings.DASHBOARD_VERSION}`,
      environment: window.location.hostname,
      integrations: [
        browserTracingIntegration({
          router
          // Disabled API tracing because they cause CORS error 'header not allowed on preflight request'
          // tracingOrigins: ['api.socialdatabase.com', 'api.staging.socialdatabase.com'],
        }),
      ],
      tracesSampleRate: 1.0,
    })

    watchEffect(() => {
      const user = currentUser.value
      getCurrentScope().setUser(user ? { ...user, id: `${user.id}` } : null)
    })
  }
}
